<template functional>
  <svg
    width="91"
    height="104"
    viewBox="0 0 91 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="vertical-align: top"
  >
    <path
      d="M19.6032 96.3005C19.3805 96.3005 19.1577 96.3005 18.9349 96.1889C15.1479 95.073 12.1406 92.5065 10.2471 89.0473C8.35354 85.588 8.01939 81.5709 9.13322 77.7769C9.46737 76.5494 10.6926 75.8799 11.9178 76.3262C13.143 76.661 13.8113 77.8885 13.3658 79.1159C11.695 84.5838 14.8138 90.2747 20.1601 91.9486C21.3854 92.2833 22.0537 93.5108 21.6081 94.7383C21.3854 95.7426 20.4943 96.3005 19.6032 96.3005Z"
      fill="#EAAB00"
    />
    <path
      d="M15.705 104C15.4822 104 15.2594 104 15.0367 103.888C9.4675 101.991 5.01218 98.0859 2.33898 92.8412C-0.222828 87.5966 -0.668361 81.5708 1.22515 76.103C1.67068 74.9871 2.8959 74.3176 4.00973 74.6524C5.12356 75.0987 5.79186 76.3262 5.45771 77.4421C4.00973 81.9056 4.34388 86.5923 6.34878 90.8326C8.46506 95.073 12.0293 98.1975 16.4846 99.6481C17.5985 100.094 18.2668 101.322 17.9326 102.438C17.4871 103.331 16.596 104 15.705 104Z"
      fill="#EAAB00"
    />
    <path
      d="M58.0307 9.87726C57.1397 9.87726 56.36 9.43091 56.0259 8.53821C55.2462 6.86439 53.7982 5.52533 52.0161 4.8558C50.2339 4.18628 48.3404 4.29786 46.5583 5.07898C45.4445 5.63692 44.1079 5.07898 43.6623 3.9631C43.1054 2.84722 43.6623 1.50816 44.7762 1.06181C50.5681 -1.6163 57.4738 0.950225 60.147 6.7528C60.7039 7.86868 60.147 9.20774 59.0332 9.65409C58.699 9.76568 58.3649 9.87726 58.0307 9.87726Z"
      fill="#EAAB00"
    />
    <path
      d="M10.6927 28.678C9.4675 28.678 8.57644 27.7853 8.46505 26.5578C8.24229 23.4334 9.35612 20.4205 11.4724 18.0772C13.5887 15.7338 16.4846 14.3947 19.6034 14.1716C20.8286 14.06 21.831 15.0643 21.9424 16.2917C22.0538 17.5192 21.0513 18.5235 19.8261 18.6351C17.8212 18.7467 16.0391 19.6394 14.8139 21.09C13.4773 22.5407 12.809 24.4377 12.9204 26.3347C13.0318 27.5621 12.0293 28.5664 10.8041 28.678H10.6927Z"
      fill="#EAAB00"
    />
    <path
      d="M3.34153 27.339C2.11632 27.339 1.11387 26.3347 1.11387 25.2188C1.00249 20.6437 2.67323 16.1802 5.90334 12.8325C9.13346 9.4849 13.366 7.58791 18.0441 7.47632C19.2693 7.47632 20.2718 8.36902 20.3831 9.59649C20.3831 10.824 19.4921 11.8282 18.2669 11.9398C14.814 12.0514 11.6953 13.5021 9.24484 15.957C6.68303 18.4119 5.45781 21.648 5.56919 25.1072C5.56919 26.3347 4.56675 27.339 3.34153 27.339Z"
      fill="#EAAB00"
    />
    <path
      d="M81.9779 43.1845C80.7527 43.1845 79.8616 42.2918 79.7502 41.0644C79.6389 39.0558 78.7478 37.2704 77.2998 36.0429C75.8518 34.7038 73.9583 34.0343 72.0648 34.1459C70.8396 34.2575 69.8371 33.2532 69.7258 32.0257C69.6144 30.7983 70.6168 29.794 71.842 29.6824C74.9608 29.4592 77.9681 30.5751 80.3071 32.6953C82.6462 34.8154 83.9828 37.7167 84.2056 40.8412C84.3169 42.0686 83.3145 43.0729 82.0893 43.1845C82.0893 43.1845 82.0893 43.1845 81.9779 43.1845Z"
      fill="#EAAB00"
    />
    <path
      d="M88.7724 41.5106C87.5472 41.5106 86.5448 40.5063 86.5448 39.3905C86.4334 35.9312 84.9854 32.8068 82.535 30.3518C80.0845 28.0085 76.743 26.781 73.4015 26.781C72.1763 26.781 71.1739 25.8883 71.0625 24.6608C71.0625 23.4334 71.9536 22.4291 73.1788 22.3175C77.8569 22.2059 82.2008 23.8797 85.5423 27.1158C88.8838 30.3518 90.7773 34.5922 90.8887 39.2789C91.1115 40.3947 90.109 41.5106 88.7724 41.5106C88.7724 41.5106 88.8838 41.5106 88.7724 41.5106Z"
      fill="#EAAB00"
    />
    <path
      d="M45.7785 93.3992C33.1922 93.3992 22.7222 83.4679 22.1653 70.7469V40.1717C22.1653 36.8241 24.8385 34.146 28.18 34.146C31.5215 34.146 34.1947 36.8241 34.1947 40.1717V55.3477C34.1947 56.5752 33.1922 57.5795 31.967 57.5795C30.7418 57.5795 29.7393 56.5752 29.7393 55.3477V40.0602C29.7393 39.1675 29.071 38.4979 28.18 38.4979C27.2889 38.4979 26.6206 39.1675 26.6206 40.0602V70.6353C27.0661 81.1245 36.0882 89.382 46.6696 88.9357C57.251 88.4893 65.4933 79.4507 65.0478 68.8499V48.0945C65.0478 46.867 64.0453 45.9743 62.9315 45.9743C61.7063 45.9743 60.8152 46.9786 60.8152 48.0945V67.6224H58.5876C52.7956 67.6224 48.1175 72.3091 48.1175 78.1117V80.3434C48.1175 81.5709 47.1151 82.5752 45.8899 82.5752C44.6647 82.5752 43.6622 81.5709 43.6622 80.3434V78.1117C43.6622 70.6353 49.12 64.4979 56.3599 63.382V48.0945C56.3599 44.4121 59.3672 41.5108 62.9315 41.5108C66.6071 41.5108 69.5031 44.5237 69.5031 48.0945V68.7383C70.06 81.6825 59.9242 92.7297 46.8923 93.2876C46.4468 93.3992 46.1126 93.3992 45.7785 93.3992Z"
      fill="#EAAB00"
    />
    <path
      d="M49.6769 57.4678C48.4517 57.4678 47.4492 56.4635 47.4492 55.236V29.236C47.4492 25.5536 50.4566 22.6523 54.0208 22.6523C57.6965 22.6523 60.5924 25.6652 60.5924 29.236V48.0944C60.5924 49.3219 59.59 50.3262 58.3648 50.3262C57.1396 50.3262 56.1371 49.3219 56.1371 48.0944V29.236C56.1371 28.0086 55.1347 27.1159 54.0208 27.1159C52.7956 27.1159 51.9045 28.1202 51.9045 29.236V55.236C51.9045 56.4635 50.9021 57.4678 49.6769 57.4678Z"
      fill="#EAAB00"
    />
    <path
      d="M40.4319 57.4678C39.2067 57.4678 38.2042 56.4635 38.2042 55.236V29.1245C38.2042 28.0086 37.3132 27.0043 36.088 27.0043C34.8627 27.0043 33.9717 27.897 33.9717 29.1245V40.0601C33.9717 41.2876 32.9692 42.2918 31.744 42.2918C30.5188 42.2918 29.5164 41.2876 29.5164 40.0601V29.1245C29.5164 25.5536 32.4123 22.5408 36.088 22.5408C39.7636 22.5408 42.6596 25.4421 42.6596 29.1245V55.236C42.6596 56.4635 41.6571 57.4678 40.4319 57.4678Z"
      fill="#EAAB00"
    />
    <path
      d="M49.6769 31.4677C48.4516 31.4677 47.4492 30.4634 47.4492 29.236V23.2102C47.4492 21.8711 46.3354 20.7553 44.9988 20.7553C43.6622 20.7553 42.5483 21.8711 42.5483 23.2102V29.1244C42.5483 30.3518 41.5459 31.3561 40.3207 31.3561C39.0955 31.3561 38.093 30.3518 38.093 29.1244V23.2102C38.093 19.4162 41.2117 16.2917 44.9988 16.2917C48.7858 16.2917 51.9045 19.4162 51.9045 23.2102V29.236C51.9045 30.4634 50.9021 31.4677 49.6769 31.4677Z"
      fill="#EAAB00"
    />
  </svg>
</template>

<script>
export default { name: 'ImgNoAccess' }
</script>
